import React from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../provider/AuthProvider';

const YouTube = () => {
    
    return (
      <div className="flex items-center h-[calc(100vh-72px)]">
        <div className="flex flex-col gap-9 bg-[#fafbfb] h-full">
          <h1 className="font-bold text-[102px] leading-[95px]">
             Share Your Vision with the World
          </h1>
          <p className="font-semibold text-[#ceb785] max-w-[400px] ml-[10px]">
          Whether you’re a vlogger, filmmaker, or business owner, uploading your videos has never been easier. Share your story with the world in just one click!
          </p>
          <div className="flex gap-10 ml-[15px]">
            <a href="https://www.youtube.com/upload" target="_blank" rel="noopener noreferrer">
            <button className="px-4 py-2 bg-[#3e82f7] text-black text-2xl shadow-md hover:bg-[#083fe2]">
              YouTube
            </button>
            </a>
            <a href="https://hootsuite.com/dashboard" target="_blank" rel="noopener noreferrer">
            <button className="px-4 py-2 bg-[#3e82f7] text-[#2e2e2e] text-2xl border border-gray-500 hover:bg-[#083fe2]">
              HootSuite
            </button>
            </a>
          </div>
        </div>
      </div>
    );
  }
  
export default YouTube;
