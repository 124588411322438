import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Send, PlayCircle, Globe, Mic, Clock, ChevronRight, ChevronLeft, Check, ArrowLeft } from 'lucide-react';

import {
  contentOptions, destinationOptions, languageOptions,
  durationOptions, serverbaseURL, narrationOptions, ChannelPrompts
} from '../constant/index.jsx';

const ViewGenerations = () => {
  const imageGenToolOptions = ['DALL-E', 'Midjourney', 'Stable Diffusion'];
  const voiceModelOptions = ['Default', 'Neural TTS', 'Custom Voice'];

  const location = useLocation();
  const navigate = useNavigate();
  const [videoItem, setVideoItem] = useState(null);
  const [generations, setGenerations] = useState(null);

  const [advancedData, setAdvancedData] = useState(location.state.item);
  useEffect(() => {
    if (location.state && location.state.item) {
      setVideoItem(location.state.item);

      setAdvancedData(location.state.item);
      setGenerations([
        {
          'videoId': '1',
          'genDate': new Date().toLocaleString(),
          'uploadedTo': 'Youtube',
          'channelID': '',
          'videoLink': 'https://www.youtube.com/watch?v=TBIjgBVFjVI&t=15s'
        },
        {
          'videoId': '1',
          'genDate': new Date().toLocaleString(),
          'uploadedTo': 'Youtube',
          'channelID': '',
          'videoLink': 'https://www.youtube.com/watch?v=TBIjgBVFjVI&t=15s'
        }]);
    } else {
      navigate('/');
    }
  }, [location, navigate]);

  const handleAdvancedChange = (e) => {
    const { name, value } = e.target;
    setAdvancedData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

 
  const renderAdvancedForm = () => {
    return (
      generations.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b">Video ID</th>
                <th className="py-2 px-4 border-b">Generation Date</th>
                <th className="py-2 px-4 border-b">Uploaded To</th>
                <th className="py-2 px-4 border-b">Channel ID</th>
                <th className="py-2 px-4 border-b">Video Link</th>
              </tr>
            </thead>
            <tbody>
              {generations.map((item) => (
                <tr key={item.videoId} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b">{item.videoId}</td>
                  <td className="py-2 px-4 border-b">{item.genDate}</td>
                  <td className="py-2 px-4 border-b">{item.uploadedTo}</td>
                  <td className="py-2 px-4 border-b">{item.channelID}</td>
                  <td className="py-2 px-4 border-b">
                    <a href={item.videoLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                      {item.videoLink}
                    </a>
                  </td>
                
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-8 w-full rounded-lg bg-slate-600">
          <p className="text-white pb-8">
            You haven't started a Faceless Video channel yet.
          </p>

          <Link to="/dashboard/create">
            <button className="bg-gradient-to-r from-primary to-blue-700 text-white py-3 px-6 text-lg rounded-lg font-semibold mb-5">
              CREATE YOUR CHANNEL
            </button>
          </Link>
        </div>
      )
    );
  };

  if (!videoItem) {
    return <div>Loading...</div>;
  }
  console.log(advancedData);
  return (
    <div className="edit-video-container p-6 bg-gray-100 rounded-lg shadow-md">
      <div className="mb-6">
        <Link
          to="/dashboard"
          className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out"
        >
          <ArrowLeft className="mr-2" size={20} />
          <span className="text-lg font-semibold">Back to Dashboard</span>
        </Link>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-sm">
        {renderAdvancedForm()}
      </div>
    </div>
  
  );
};

export default ViewGenerations;
